// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/icons/eye.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/icons/eye.tsx");
  import.meta.hot.lastModified = "1726716722998.0098";
}
// REMIX HMR END

export function EyeOn() {
  return <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9707 10C13.9707 10.7956 13.6546 11.5587 13.092 12.1213C12.5294 12.6839 11.7664 13 10.9707 13C10.1751 13 9.41199 12.6839 8.84938 12.1213C8.28677 11.5587 7.9707 10.7956 7.9707 10C7.9707 9.20435 8.28677 8.44129 8.84938 7.87868C9.41199 7.31607 10.1751 7 10.9707 7C11.7664 7 12.5294 7.31607 13.092 7.87868C13.6546 8.44129 13.9707 9.20435 13.9707 10ZM12.4707 10C12.4707 10.3978 12.3127 10.7794 12.0314 11.0607C11.7501 11.342 11.3685 11.5 10.9707 11.5C10.5729 11.5 10.1913 11.342 9.91004 11.0607C9.62874 10.7794 9.4707 10.3978 9.4707 10C9.4707 9.60218 9.62874 9.22064 9.91004 8.93934C10.1913 8.65804 10.5729 8.5 10.9707 8.5C11.3685 8.5 11.7501 8.65804 12.0314 8.93934C12.3127 9.22064 12.4707 9.60218 12.4707 10Z" fill="#636969" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.9707 4C8.4947 4 6.6227 5.23 5.3937 6.532C4.84643 7.10988 4.37589 7.75587 3.9937 8.454C3.85005 8.71701 3.72637 8.99045 3.6237 9.272C3.5417 9.499 3.4707 9.76 3.4707 10C3.4707 10.24 3.5417 10.501 3.6227 10.728C3.7107 10.974 3.8357 11.252 3.9937 11.546C4.3107 12.133 4.7777 12.816 5.3937 13.468C6.6227 14.77 8.4937 16 10.9707 16C13.4467 16 15.3187 14.77 16.5477 13.468C17.095 12.8901 17.5655 12.2441 17.9477 11.546C18.0914 11.283 18.215 11.0096 18.3177 10.728C18.3997 10.501 18.4707 10.24 18.4707 10C18.4707 9.76 18.3997 9.499 18.3187 9.272C18.2157 8.99042 18.0917 8.71699 17.9477 8.454C17.5655 7.75588 17.095 7.1099 16.5477 6.532C15.3187 5.23 13.4477 4 10.9707 4ZM4.9717 10.002V9.998C4.9757 9.978 4.9887 9.908 5.0357 9.775C5.11317 9.56572 5.20609 9.36249 5.3137 9.167C5.63342 8.58414 6.02663 8.04473 6.4837 7.562C7.5257 6.458 9.0287 5.5 10.9707 5.5C12.9127 5.5 14.4157 6.458 15.4567 7.562C15.9138 8.04475 16.307 8.58415 16.6267 9.167C16.7567 9.407 16.8477 9.614 16.9057 9.775C16.9527 9.907 16.9657 9.978 16.9697 9.998V10.002C16.9657 10.022 16.9527 10.092 16.9057 10.225C16.8282 10.4343 16.7353 10.6375 16.6277 10.833C16.308 11.4159 15.9148 11.9553 15.4577 12.438C14.4157 13.542 12.9127 14.5 10.9707 14.5C9.0287 14.5 7.5257 13.542 6.4847 12.438C6.02761 11.9553 5.6344 11.4159 5.3147 10.833C5.20675 10.6375 5.11349 10.4343 5.0357 10.225C4.9887 10.093 4.9757 10.022 4.9717 10.002Z" fill="#636969" />
    </svg>;
}
_c = EyeOn;
export function EyeOff() {
  return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.977 5.2511C11.3319 5.081 10.6672 4.99661 10 5.0001C7.556 5.0001 5.804 6.0451 4.675 7.2331C4.17352 7.75795 3.75443 8.35573 3.432 9.0061C3.172 9.5381 3 10.0821 3 10.5001C3 10.9181 3.171 11.4621 3.432 11.9931C3.604 12.3471 3.832 12.7271 4.119 13.1091L5.193 12.0351C5.03756 11.8125 4.89917 11.5785 4.779 11.3351C4.558 10.8821 4.5 10.5821 4.5 10.5001C4.5 10.4181 4.558 10.1181 4.779 9.6651C5.03468 9.15243 5.36606 8.68115 5.762 8.2671C6.652 7.3301 8.026 6.5001 10 6.5001C10.24 6.5001 10.471 6.5121 10.693 6.5361L11.977 5.2511Z" fill="#636969" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.25 15.0977C4.11832 15.2407 4.04732 15.4293 4.05197 15.6237C4.05663 15.818 4.13657 16.003 4.27494 16.1395C4.41332 16.2761 4.59931 16.3536 4.79371 16.3557C4.98811 16.3577 5.17573 16.2843 5.317 16.1507L6.379 15.0897C7.354 15.6327 8.556 15.9977 10 15.9977C12.45 15.9977 14.142 14.9477 15.24 13.7557C16.318 12.5857 16.828 11.2797 16.978 10.6797C17.0079 10.5602 17.0079 10.4352 16.978 10.3157C16.828 9.7157 16.318 8.4097 15.24 7.2397C15.0784 7.06407 14.9082 6.89653 14.73 6.7377L15.653 5.8147C15.791 5.67429 15.8682 5.48526 15.868 5.28841C15.8679 5.09156 15.7903 4.90267 15.6521 4.7625C15.5139 4.62232 15.3261 4.5421 15.1293 4.53915C14.9325 4.53619 14.7424 4.61074 14.6 4.7467L14.592 4.7547L4.257 15.0907L4.25 15.0977ZM10 14.4977C9.022 14.4977 8.191 14.2937 7.494 13.9747L8.602 12.8657C9.12722 13.1757 9.74058 13.3022 10.3456 13.2255C10.9506 13.1488 11.513 12.8731 11.9442 12.4419C12.3754 12.0107 12.6511 11.4483 12.7278 10.8433C12.8045 10.2383 12.678 9.62493 12.368 9.0997L13.668 7.8007C13.837 7.9477 13.993 8.1007 14.137 8.2557C14.7305 8.90348 15.1839 9.66672 15.469 10.4977C15.1839 11.3287 14.7305 12.0919 14.137 12.7397C13.277 13.6727 11.967 14.4977 10 14.4977ZM10 11.7477C9.913 11.7477 9.828 11.7377 9.746 11.7217L11.224 10.2437C11.2617 10.4255 11.2585 10.6135 11.2145 10.7939C11.1705 10.9743 11.0868 11.1427 10.9696 11.2867C10.8524 11.4308 10.7045 11.5469 10.5368 11.6266C10.3691 11.7063 10.1857 11.7477 10 11.7477Z" fill="#636969" />
    </svg>;
}
_c2 = EyeOff;
var _c, _c2;
$RefreshReg$(_c, "EyeOn");
$RefreshReg$(_c2, "EyeOff");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;